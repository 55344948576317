import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { OptionalMandatoryState } from "../../enums/site-document-state.enum";
import {
  SiteSupplierDocumentTypeModel,
  SiteSupplierDocumentTypeViewModel
} from "../../models/supplier-document/site-supplier-document-type-view-model.model";
import { SiteSupplierViewModel } from 'src/app/models/supplier-document/site-supplier-view-model.model';
import { debounceTime, finalize } from "rxjs/operators";
import { SupplierDocumentService } from "../../services/supplier-document.service";
import { SupplierDocumentTypeViewModel } from "../../models/supplier-document/supplier-document-models";
import { ModalService } from "../../services/modal.service";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { LayoutService } from "../../services/layout.service";
import { SiteSupplierHashLinkModel } from "../../models/supplier-document/site-supplier-hash-link-model.model";
import { ApproveDeclineSiteSupplierDocumentVersion } from "../../models/supplier-document/site-supplier-document-type-models";
import { SiteSupplierDocumentStatus } from 'src/app/enums/site-supplier-document-status';
import { BehaviorSubject } from 'rxjs';
import { ExternalSitePermission } from 'src/app/enums/external-site-permission';
import { clone, getPageSizeByUrl } from 'src/app/helpers/general-functions';
import { FormDataStatus } from 'src/app/enums/form-data-status';
import { SiteAssetRequestApproveStatus } from 'src/app/enums/site-asset-request-approve-status.enum';
import { OnboardingType } from 'src/app/enums/onboarding-type.enum';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { SiteSupplierAccessViewModel } from 'src/app/models/link_to_company_request_model';
import { CompanySupplierModel } from 'src/app/models/supplier-models';
import { e168ValidatorNullable } from 'src/app/helpers/mobile-format-validator';
import { MessageQueryModel } from 'src/app/models/message-query-model';
import { MessageQueryType } from 'src/app/enums/message-query-type.enum';
import { UserService } from 'src/app/services/user.service';
import { PagingRequest } from 'src/app/models/paging-request-model';
import { SiteSupplierRequestModel } from 'src/app/models/supplier-document/site-supplier-doc-type-forms-view-model';
import { PaginationInfo } from 'src/app/models/pagination-info';
import { BasePagingAndSortingModel } from 'src/app/models/paging_model';
import { SortOrder } from 'src/app/enums/sort-order-enum';

@Component({
  selector: 'obc-supplier-documents',
  templateUrl: './supplier-documents.component.html',
  styleUrls: ['./supplier-documents.component.scss']
})
export class SupplierDocumentsComponent implements OnInit {
  inProgress: boolean = false;
  totalCount: number;
  paginationInfo: BasePagingAndSortingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 20,
    sortBy: '',
    sortOrder: SortOrder.ASC
  };
  // if site is global
  siteMode: boolean = false;
  SortOrder = SortOrder;

  SiteSupplierDocumentStatus = SiteSupplierDocumentStatus;
  pageYOffset: number;
  SelectedSiteSupplierForSupplierLinkOperations: SiteSupplierViewModel;
  selectedAccordionItemId: number;
  supplierDocumentFilter: string
  showMarkdownEditor: boolean;
  emailHistoryRequest: MessageQueryModel;
  modalRef: any;
  @HostListener('window:scroll', ['$event']) onScroll(_) {
    this.pageYOffset = window.pageYOffset;
  }

  selectedSiteSupplierDocument: SiteSupplierDocumentTypeViewModel;
  SiteActiveStatus = SiteActiveStatus;
  newForm: boolean = false;
  displayForm: boolean = false;
  siteId: number;
  selectedSiteSupplierId: number;
  selectedSupplierId: number;
  selectedSiteId: number;

  selectedSupplierDocumentType: SupplierDocumentTypeViewModel;
  UserPermission = UserPermission;
  ExternalSitePermission = ExternalSitePermission;
  FormDataStatus = FormDataStatus;
  SiteAssetRequestApproveStatus = SiteAssetRequestApproveStatus;
  supplierFormControl = new FormControl();
  supplierDocumentFormControl = new FormControl();
  supplierDocumentStatus: OptionalMandatoryState = OptionalMandatoryState.Optional;
  siteSupplierAccessViewModel: SiteSupplierAccessViewModel

  sampleMandatorySiteDocumentState: OptionalMandatoryState = OptionalMandatoryState.Mandatory;
  renameNoneStandardSupplierDocumentTypeFormControl = new FormControl(null, [Validators.required]);
  allSiteSupplierDocumentTypes: SiteSupplierViewModel[] = [];
  hasSupplierReviewForm: boolean;
  selectedSiteSupplierDocumentTypes: SiteSupplierViewModel;
  selectedDocumentTypes: SupplierDocumentTypeViewModel[] = [];
  selectedSites: number[] = [];
  selectedSuppliers: number[] = [];
  selectedSupplierIds: number[] = [];

  firstEmail = false;
  email = new FormControl();
  emailSubject = new FormControl();
  emailBody = new FormControl();
  addOffsiteTemplate = new FormControl(true);
  mobile = new FormControl();
  offsiteEmailTemplate = new FormControl();

  selectedFormDataId: number;
  bsModalRef: BsModalRef;
  siteSupplierToEditExternalSitePermissions: SiteSupplierViewModel;

  OnboardingType = OnboardingType
  onboardingFormControl: FormControl

  bsModalHandle: any;
  updateSupplierFormGroup: FormGroup;
  generalSearcControl = new FormControl();

  @ViewChild('supplierSelector') supplierSelector;
  @ViewChild('supplierDocumentSelector') supplierDocumentSelector;


  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private supplierDocumentService: SupplierDocumentService,
    private layoutService: LayoutService,
    private router: Router,
    private companySupplierService: CompanySupplierService,
    private userService: UserService,
  ) {
    this.onboardingFormControl = new FormControl();
    this.onboardingFormControl.setValue(OnboardingType.Quick);
    this.onboardingFormControl.valueChanges.subscribe(res => {
      let emailInfo = res == OnboardingType.Full ?
        this.SelectedSiteSupplierForSupplierLinkOperations.fullOnboardingEmailInfoViewModel :
        this.SelectedSiteSupplierForSupplierLinkOperations.quickOnboardingEmailInfoViewModel;

      this.emailBody.setValue(emailInfo.body);
      this.emailSubject.setValue(emailInfo.subject);

      this.resetMarkdownEditor();
    })

    this.updateSupplierFormGroup = new FormGroup({
      id: new FormControl(null, []),
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.email]),
      mobile: new FormControl(null, [e168ValidatorNullable]),
    });
  }

  ngOnInit(): void {
    this.generalSearcControl.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((searchTerm) => {
      this.supplierDocumentFilter = searchTerm;
      if (searchTerm == '' || (searchTerm != null && searchTerm.length >= 2)) {
        this.paginationInfo.pageNumber = 0;
        this._calSiteSupplierDocumentTypes();
      }
    });

    this.layoutService.header = "Assign Document Type to Supplier"
    this.supplierFormControl.valueChanges.subscribe(_ => {
      if (!this.siteMode) {
        this.resetSearchTermValue();
        this._calSiteSupplierDocumentTypes();
      }
    });

    this.route.params.subscribe((params: Params) => {
      const supplierId = params['supplierId'] ? +params['supplierId'] : null;
      if (params['siteId']) {
        this.siteMode = true;
        this.changeSiteId(+params['siteId']);
      }

      if (supplierId && supplierId > 0) {
        this.supplierFormControl.setValue(supplierId);
      }
    });
    //To handle backdrop-click
    // this.bsModalService.onHide.subscribe((_) => {
    //   this.refresh();
    // });

    // this.bsModalRef.content.onClose.subscribe((_) => {
    //   this.refresh();
    // });
  }

  toggleSort() {
    if (this.paginationInfo.sortOrder == null)
      this.paginationInfo.sortOrder = SortOrder.ASC;
    else if (this.paginationInfo.sortOrder == SortOrder.ASC) this.paginationInfo.sortOrder = SortOrder.DESC;
    else if (this.paginationInfo.sortOrder == SortOrder.DESC) this.paginationInfo.sortOrder = null;
    this.paginationInfo.pageNumber = 0;
    this._calSiteSupplierDocumentTypes();
  }

  changeSiteId(value) {
    if (this.siteId != value) {
      this.siteId = value;
      this.resetSearchTermValue();
      this._calSiteSupplierDocumentTypes();
    }
  }

  resetSearchTermValue() {
    this.generalSearcControl.setValue('', { emitEvent: false });
    this.supplierDocumentFilter = '';
    console.log(this.generalSearcControl.value);
  }

  onSelectSiteOrSupplier(id) {

    if (!this.siteMode)
      this.siteId = id;

    let foundItem = this.allSiteSupplierDocumentTypes.find(item => item?.siteId == this.siteId && item?.supplierId == this.supplierFormControl.value);
    if (foundItem) {
      this._loadItem(foundItem);
    } else {
      this.selectedSiteSupplierDocumentTypes = null;
      this.selectedDocumentTypes = [];
      // this.supplierDocumentSelector?.updateSelector(this.selectedDocumentTypes);
    }
  }

  onSelectSupplierDocumentType(documentType) {
    this.selectedSupplierDocumentType = documentType;
  }

  forceToResetValues = new BehaviorSubject<boolean>(true);

  removeSiteSupplierDocumentType(ss: SiteSupplierViewModel, ssdt: SiteSupplierDocumentTypeViewModel,) {

    const index = ss.siteSupplierDocumentTypes.indexOf(ssdt);
    if (index > -1) {
      ss.siteSupplierDocumentTypes.splice(index, 1);
    }

    this.forceToResetValues.next(false);

    this.selectedDocumentTypes = ss.siteSupplierDocumentTypes.filter(x => {
      return x.supplierDocumentTypeId;
    }).map(x => {
      return { id: x.supplierDocumentTypeId, name: x.supplierDocumentTypeName } as SupplierDocumentTypeViewModel
    })

    setTimeout(() => {
      this.forceToResetValues.next(true);
    }, 100)
  }

  documentTypeStatusChanged(value, item?: SiteSupplierDocumentTypeViewModel) {
    if (item) {
      item.mode = value ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
    } else {
      this.supplierDocumentStatus = value ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
    }
  }

  onAssignDocumentTypeToSupplier() {
    let selectedSupplierDocumentType = this.selectedSupplierDocumentType;
    let newItem = {
      supplierDocumentTypeId: selectedSupplierDocumentType.id,
      supplierDocumentTypeName: selectedSupplierDocumentType.name,
      mode: this.supplierDocumentStatus
    } as SiteSupplierDocumentTypeViewModel;

    this.selectedSiteSupplierDocumentTypes = this.selectedSiteSupplierDocumentTypes || {
      siteId: this.siteId,
      supplierId: this.supplierFormControl.value,
      siteSupplierDocumentTypes: []
    } as SiteSupplierViewModel;

    let foundItem = this.selectedSiteSupplierDocumentTypes.siteSupplierDocumentTypes.find(item => item?.supplierDocumentTypeId == selectedSupplierDocumentType.id);
    if (foundItem) {
      this.selectedSiteSupplierDocumentTypes.siteSupplierDocumentTypes = this.selectedSiteSupplierDocumentTypes.siteSupplierDocumentTypes.map(
        function (item) {
          return item.supplierDocumentTypeId == selectedSupplierDocumentType.id ? newItem : item;
        });
    } else {
      this.selectedSiteSupplierDocumentTypes.siteSupplierDocumentTypes.push(newItem);
    }

    this.selectedDocumentTypes = [...this.selectedDocumentTypes, selectedSupplierDocumentType];
  }

  onSaveSDT() {
    let siteId = this.siteId;
    let supplierId = this.supplierFormControl.value;

    if (!(siteId && supplierId)) {
      this._clearForm();
      return;
    }

    let dataToServer = {
      siteId: siteId,
      supplierId: supplierId,
      supplierDocumentTypes: this.selectedSiteSupplierDocumentTypes?.siteSupplierDocumentTypes
    } as SiteSupplierDocumentTypeModel;

    let foundItem = this.allSiteSupplierDocumentTypes.find(item => item?.siteId == siteId && item?.supplierId == supplierId);
    if (foundItem) {
      let result = this.supplierDocumentService.editSiteSupplierDocumentTypes(this.selectedSiteSupplierId, dataToServer);
      result.subscribe(_ => {
        this._calSiteSupplierDocumentTypes();
        this.closeNewSiteSupplierModal();
      }, err => {
        this.modalService.error(err, "Unable to edit");
      })
    } else {
      let result = this.supplierDocumentService.addSiteSupplierDocumentTypes(dataToServer);
      result.subscribe(_ => {
        this._calSiteSupplierDocumentTypes();
        this.closeNewSiteSupplierModal();
      }, err => {
        this.modalService.error(err, "Unable to add");
      })
    }

    this._clearForm();
  }

  async onSiteSupplierStatusChange(value: boolean, item: SiteSupplierViewModel) {
    let modalResult = true;
    if (value === false) {
      modalResult = await this.modalService.confirm(`Are you sure you want to remove ${!this.siteMode ? 'site' : 'supplier'} for this ${!this.siteMode ? 'supplier' : 'site'}?`, `Remove ${item.supplierName}`).toPromise();
    }
    if (modalResult) {
      let result = this.supplierDocumentService.editSiteSupplierDocumentTypeEnableStatus(item.siteSupplierId, value);
      result.subscribe(res => {
        if (res.success)
          this._calSiteSupplierDocumentTypes();
        else
          this.modalService.error(res.message);
        this.bsModalHandle.hide();
      }, err => {
        this.modalService.error(err, "Unable to remove this item");
      });
    }
  }

  onRemoveSDT(item: SiteSupplierViewModel) {
    this.modalService.confirm("Are You Sure?", `Delete ${item.supplierName}`).subscribe(result => {
      if (result) {
        if (item.siteSupplierId === this.selectedSiteSupplierId)
          this.selectedSiteSupplierId = null;

        let result = this.supplierDocumentService.removeSiteSupplierDocumentTypes(item.siteSupplierId);
        result.subscribe(res => {
          if (res.success) {
            this.paginationInfo.pageNumber = 0;
            this._calSiteSupplierDocumentTypes();
          }
          else
            this.modalService.error(res.message);
        }, err => {
          this.modalService.error(err, "Unable to delete this item");
        });
      }
    })
  }

  onEditSDT(item: SiteSupplierViewModel) {
    this.siteId = item.siteId;
    this._loadItem(item);
  }

  currentLink = null;
  refViewHashLink: any;

  onViewHashLink(item: SiteSupplierViewModel, template: any) {
    this.SelectedSiteSupplierForSupplierLinkOperations = item;
    this.currentLink = item.supplierHashLink;
    let email = item.lastEmailAddress;
    if (!email?.length)
      email = item.supplierEmailAddress;
    this.email.setValue(email);
    this.emailBody.setValue(item.supplierEmailBody);
    this.emailSubject.setValue(item.supplierEmailSubject);
    this.addOffsiteTemplate.setValue(item.isOffsiteEnabled);
    this.refViewHashLink = this.bsModalService.show(template, { class: 'modal-lg' });
    this.firstEmail = item.lastEmailAddress?.length > 0;
    this.onboardingFormControl.setValue(item.onboardingType ?? OnboardingType.Quick);
    this.mobile.setValue(item.mobile);
    this.offsiteEmailTemplate.setValue(item.offsiteEmailTemplate);
  }


  _clearForm() {
    this.displayForm = false;
    this.newForm = false;
    if (this.siteMode)
      this.supplierFormControl.setValue(null);
    else
      this.siteId = null;
    this.selectedSiteSupplierDocumentTypes = null;
    this.selectedSiteSupplierId = null;
    this.selectedDocumentTypes = [];
  }
  newSiteSuplierModalRef: any;
  _openNewForm(template: any) {
    this._clearForm();
    // this.displayForm = true;
    // this.newForm = true;
    if (this.siteMode)
      this.supplierFormControl.setValue(null);
    else
      this.siteId = null;

    this.newSiteSuplierModalRef = this.modalService.show(template);
  }

  closeNewSiteSupplierModal() {
    this._clearForm();
    this.modalService.hide();
  }

  _loadItem(item: SiteSupplierViewModel) {
    if (this.siteMode) {
      this.supplierFormControl.setValue(item.supplierId)
    }

    this.selectedDocumentTypes = [];
    let tmpItem: SiteSupplierViewModel = JSON.parse(JSON.stringify(item))
    this.displayForm = true;
    this.newForm = false;
    this.scrollToTop();
    this.selectedSiteSupplierId = tmpItem.siteSupplierId;
    this.selectedSiteSupplierDocumentTypes = tmpItem;
    tmpItem.siteSupplierDocumentTypes.map((item) => this.selectedDocumentTypes.push(
      { id: item.supplierDocumentTypeId, name: item.supplierDocumentTypeName } as SupplierDocumentTypeViewModel))
    this.newForm = false;
    this.selectedDocumentTypes = [...this.selectedDocumentTypes];
    // this.supplierDocumentSelector?.updateSelector(this.selectedDocumentTypes);
  }

  _calSiteSupplierDocumentTypes() {
    if (this.inProgress)
      return;
    this.inProgress = true;
    this.paginationInfo.sortBy = this.siteMode ? 'supplier' : 'site';

    let request = {
      pagingInfo: this.paginationInfo,
      query:
      {
        siteId: this.siteMode ? this.siteId : null,
        supplierId: this.siteMode ? null : this.supplierFormControl?.value,
        supplierSearchTerm: this.siteMode ? this.supplierDocumentFilter : null,
        siteSearchTerm: this.siteMode ? null : this.supplierDocumentFilter,
      }
    } as PagingRequest<SiteSupplierRequestModel>;
    (this.siteMode ?
      this.supplierDocumentService.getSiteSupplierDocumentTypesBySiteWithStatistics(request) :
      this.supplierDocumentService.getSiteSupplierDocumentTypes(request))
      .pipe(finalize(() => {
      }))
      .subscribe(res => {
        this.inProgress = false;
        if (res != null && res) {
          this.totalCount = res.totalCount;

          this.hasSupplierReviewForm = res.info.hasSupplierReviewForm;
          this.allSiteSupplierDocumentTypes = res.data ?? [];
          this.allSiteSupplierDocumentTypes.map((item) => item.siteSupplierDocumentTypes.sort((a, b) => (a.mode < b.mode) ? 1 : -1))
          this.selectedSupplierIds = res.data?.map(item => item.supplierId);
          this._clearForm();
          this.selectedSites = this.allSiteSupplierDocumentTypes.map((item) => item.siteId);
        } else {
          this.allSiteSupplierDocumentTypes = [];
          this.hasSupplierReviewForm = false;
          this._clearForm();
        }
      }, err => {
        this.inProgress = false;
        this.modalService.error(err, "Unable to get information");
      }, () => {
        this.inProgress = false;
      });
  }

  onPagingUpdate(pagingInfo: PaginationInfo) {
    this.paginationInfo.pageNumber = pagingInfo.pageNumber;
    this.paginationInfo.pageSize = pagingInfo.pageSize;
    this._calSiteSupplierDocumentTypes();
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }
  onEmailBodyChanged(value, emailBodyType: string) {
    if (emailBodyType == 'emailBodyRichBox')
      this.emailBody.setValue(value);
    if (emailBodyType == 'offsiteEmailBodyRichBox')
      this.offsiteEmailTemplate.setValue(this.addOffsiteTemplate.value ? value : '');
  }

  onIncludeOffsiteInductionChange(value) {
    if (value.currentTarget.checked)
      this.offsiteEmailTemplate.setValue(this.SelectedSiteSupplierForSupplierLinkOperations.offsiteEmailTemplate);
    else
      this.offsiteEmailTemplate.setValue("");
  }

  resetMarkdownEditor() {
    this.showMarkdownEditor = false;
    setTimeout(() => {
      this.showMarkdownEditor = true;
    }, 100);
  }

  onSendEmail() {
    if (!this.SelectedSiteSupplierForSupplierLinkOperations?.siteSupplierId)
      return;
    if (!this.email?.value) {
      this.modalService.error("Please enter supplier email address!");
      return;
    }
    if (this.emailBody?.value.length > this.userService.editorMaxLength.value) {
      this.modalService.error('Text field length exceeded the maximum limit. Please reduce the length of your input and try again.');
      return;
    }
    this.supplierDocumentService.sendEmail({
      toEmailAddress: this.email?.value,
      supplierLinkBody: this.emailBody?.value,
      supplierLinkSubject: this.emailSubject?.value,
      siteSupplierId: this.SelectedSiteSupplierForSupplierLinkOperations?.siteSupplierId,
      onboardingType: this.onboardingFormControl.value,
      mobile: this.mobile?.value,
      addOffsiteTemplate: this.addOffsiteTemplate.value,
      siteId: this.SelectedSiteSupplierForSupplierLinkOperations.siteId,
      companySupplierId: this.SelectedSiteSupplierForSupplierLinkOperations.supplierId,
      offsiteTemplateBody: this.addOffsiteTemplate.value ? this.offsiteEmailTemplate.value : ''
    } as SiteSupplierHashLinkModel)
      .subscribe(_ => {
        this._calSiteSupplierDocumentTypes();
        this.refViewHashLink.hide();
      }, err => {
        this.modalService.error(err, "Unable to send email");
      });
  }

  onApproveDeclineSiteSupplierVersion(isApprove: boolean, documentType: SiteSupplierDocumentTypeViewModel) {
    if (!documentType)
      return;
    this.supplierDocumentService.approveDeclineSiteSupplierVersion({
      isApproved: isApprove,
      siteSupplierDocumentVersionId: documentType.lastSupplierVersionId
    } as ApproveDeclineSiteSupplierDocumentVersion)
      .subscribe(_ => {
        this._calSiteSupplierDocumentTypes();
      }, err => {
        this.modalService.error(err, "Unable to change status");
      });
  }

  isInViewMode(item: SiteSupplierViewModel) {
    return !(item && this.siteId == item.siteId && this.supplierFormControl.value == item.supplierId)
  }

  isPending(item: SiteSupplierDocumentTypeViewModel) {
    return item.status == SiteSupplierDocumentStatus.Pending;
  }

  isDocumentTypeIsVisible(index, docType: SiteSupplierDocumentTypeViewModel, siteSupplier: SiteSupplierViewModel) {
    return (docType.status == SiteSupplierDocumentStatus.Pending || (index < 5)) || (siteSupplier != null && siteSupplier._showAll);
  }

  hasHiddenItems(siteSupplier: SiteSupplierViewModel): boolean {
    let ret = false;
    for (let index = 0; index < siteSupplier.siteSupplierDocumentTypes?.length; index++) {
      const element = siteSupplier.siteSupplierDocumentTypes[index];
      ret = !this.isDocumentTypeIsVisible(index, element, null);
      if (ret == true)
        return true;
    }
    return ret;
  }

  isDocumentTypeIsVisibleInEditMode(index, docType: SiteSupplierDocumentTypeViewModel, siteSupplier: SiteSupplierViewModel) {
    return (!docType.id || (index < 5)) || (siteSupplier != null && siteSupplier._showAll);
  }

  hasHiddenItemsInEditMode(siteSupplier: SiteSupplierViewModel): boolean {
    let ret = false;
    for (let index = 0; index < siteSupplier.siteSupplierDocumentTypes?.length; index++) {
      const element = siteSupplier.siteSupplierDocumentTypes[index];
      ret = !this.isDocumentTypeIsVisibleInEditMode(index, element, null);
      if (ret == true)
        return true;
    }
    return ret;
  }

  bsModalRefRenameDOT: any = null;
  bsModalRefEditPermission: any;
  selectedSiteSupplierForRename: SiteSupplierViewModel = null;
  selectedSiteSupplierDocumentTypeForRename: SiteSupplierDocumentTypeViewModel = null;

  onEditNoneStandardSupplierDocumentType(siteSupplier: SiteSupplierViewModel, docType: SiteSupplierDocumentTypeViewModel, template: any) {
    this.renameNoneStandardSupplierDocumentTypeFormControl.setValue(docType.supplierDocumentTypeName);
    this.selectedSiteSupplierForRename = siteSupplier;
    this.selectedSiteSupplierDocumentTypeForRename = docType;
    this.bsModalRefRenameDOT = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }

  updateNoneStandardSupplierDocumentType() {
    const model = {
      title: this.renameNoneStandardSupplierDocumentTypeFormControl.value,
      siteSupplierId: this.selectedSiteSupplierForRename.siteSupplierId,
      siteSupplierDocumentTypeId: this.selectedSiteSupplierDocumentTypeForRename.id
    };

    this.supplierDocumentService.renameSiteSupplierDocumentType(model)
      .subscribe(_ => {
        this.bsModalRefRenameDOT.hide();
        this._calSiteSupplierDocumentTypes();
      }, err => {
        this.modalService.error(err);
      })
  }

  onOpenformReview(template: any, item: SiteSupplierDocumentTypeViewModel, supplierId: number, siteId: number) {
    this.selectedSupplierId = supplierId;
    this.selectedSiteId = siteId;
    this.selectedSiteSupplierDocument = item;
    this.bsModalRef = this.bsModalService.show(template, { class: "modal-lg" })
  }

  onSupplierDocumentFilterChange() {

  }
  refresh() {
    this._calSiteSupplierDocumentTypes();
    this.bsModalRef?.hide();
  }

  onViewFilledForm(template: any, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }

  getSupplierDocumentListCount(item: SiteSupplierViewModel, status: SiteSupplierDocumentStatus = null) {
    const types = item.siteSupplierDocumentTypes;
    return status == null ?
      types.filter(d => d.status != SiteSupplierDocumentStatus.Approved && d.status != SiteSupplierDocumentStatus.Pending && d.status != SiteSupplierDocumentStatus.Declined).length :
      types.filter(d => d.status == status).length;
  }
  getPermitCount(item: SiteSupplierViewModel, status?: FormDataStatus) {
    var countArray = item.permitStatistics.filter(d => status == null || d.status == status).map(d => d.count);
    return countArray.length ? countArray.reduce((a, b) => a + b) : 0;
  }

  getAssetCount(item: SiteSupplierViewModel, status?: SiteAssetRequestApproveStatus) {
    let countArray = item.assetStatistics.filter(d => status == null || d.status == status).map(d => d.count);
    return (countArray.length ? countArray.reduce((a, b) => a + b) : 0) +
      (status == SiteAssetRequestApproveStatus.Approved ? (item.assetStatistics.find(d => d.status == SiteAssetRequestApproveStatus.AutoApproved)?.count ?? 0) : 0);
  }

  onAccordionClick(selectedItemId: number, isOpen: boolean) {
    if (isOpen == true)
      this.selectedAccordionItemId = selectedItemId;
  }

  hasSiteSupplierPermission(permission: any, siteSupplierPermission: ExternalSitePermission) {
    let perm = typeof permission == 'number' ? permission : ExternalSitePermission[permission] as any;
    return (perm & siteSupplierPermission) > 0;
  }

  openExternalPermissionModal(template, siteSupplier: SiteSupplierViewModel) {
    this.siteSupplierToEditExternalSitePermissions = clone(siteSupplier);
    this.bsModalRefEditPermission = this.bsModalService.show(template);
  }

  updateExternalSitePermissionsValue(permission: ExternalSitePermission, e) {
    let isChecked = e.target.checked;
    this.siteSupplierToEditExternalSitePermissions.externalSitePermissions = isChecked ?
      (this.siteSupplierToEditExternalSitePermissions.externalSitePermissions | permission) :
      (this.siteSupplierToEditExternalSitePermissions.externalSitePermissions & ~permission);
    if ((this.siteSupplierToEditExternalSitePermissions.externalSitePermissions & ExternalSitePermission.ViewActivity) == 0)
      this.siteSupplierToEditExternalSitePermissions.externalSitePermissions = ExternalSitePermission.NoPermission;
  }

  updateExternalSitePermissions() {
    this.supplierDocumentService.updateExternalSitePermissions(this.siteSupplierToEditExternalSitePermissions.siteSupplierId, this.siteSupplierToEditExternalSitePermissions.externalSitePermissions)
      .subscribe((res) => {
        this.bsModalRefEditPermission.hide();
        this.refresh();
      });
  }
  getmanageItemsRouteLink(item: SiteSupplierViewModel) {
    return '/manage-site-supplier/' + item.siteSupplierId
      + '/' + item.supplierId + '/' + item.siteId + '/'
      + this.router.url.replace(/^\/+|\/+$/g, '').replace(/\//g, "_")
    // trim "/" and replace "-" with "_"
  }

  openUpsertModal(template) {
    this.bsModalHandle = this.bsModalService.show(template);
  }

  selectedSupplierViewModel: SiteSupplierViewModel;
  onEditSupplier(template: any, supplier: SiteSupplierViewModel) {
    this.updateSupplierFormGroup.controls.id.setValue(supplier.supplierId);
    this.updateSupplierFormGroup.controls.name.setValue(supplier.supplierName);
    this.updateSupplierFormGroup.controls.email.setValue(supplier.supplierEmailAddress);
    this.updateSupplierFormGroup.controls.mobile.setValue(supplier.supplierMobile);
    this.selectedSupplierViewModel = supplier;
    this.openUpsertModal(template);
  }
  updateSupplier() {
    this.inProgress = true;
    var id = +this.updateSupplierFormGroup.controls.id.value;
    this.companySupplierService.updateSupplier(
      id,
      this.getSupplierModel(),
    )
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this._calSiteSupplierDocumentTypes();
        this.updateSupplierFormGroup.reset();
        this.bsModalHandle.hide();
      }, err => {
        this.modalService.error("unable to update supplier");
      });
  }
  private getSupplierModel(): CompanySupplierModel {
    return {
      name: this.updateSupplierFormGroup.controls.name.value,
      email: this.updateSupplierFormGroup.controls.email.value,
      mobile: this.updateSupplierFormGroup.controls.mobile.value,
    } as CompanySupplierModel;
  }

  showEmailHistory(supplier: any, template: any) {
    this.emailHistoryRequest = {
      companyId: this.userService.currentCompanyId,
      siteId: supplier.siteId,
      supplierId: supplier.supplierId,
      type: MessageQueryType.OnboardingEmailHistory,
    } as MessageQueryModel;
    this.modalRef = this.bsModalService.show(template, { class: 'modal-lg' });
  }
}
