import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { Table } from 'src/app/enums/table.enum';
import { ModalService } from 'src/app/services/modal.service';
import { AsyncResult } from 'src/app/models/helper-models';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from "../../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../../models/attendance-document-report-viewmodel";
import { SiteBriefingStatusPipe } from 'src/app/pipes/site-briefing-status.pipe';
import { SiteBriefingStatus } from 'src/app/enums/site-briefing-status';
import { FileType } from 'src/app/enums/file-type.enum';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { SiteBriefingService } from 'src/app/services/site-briefing-service';
import { FormService } from 'src/app/services/form.service';
import { FormDataPdfQueryModel } from 'src/app/models/form/form-data-pdf-query-model';

@Component({
  selector: 'obc-site-briefing-report',
  templateUrl: './site-briefing-report.component.html',
  styleUrls: ['./site-briefing-report.component.scss'],
})

export class SiteBriefingReportComponent implements OnInit {
  @ViewChild('grid') grid: any;

  inProgress: boolean = false;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  selectedFormDataId: number;
  SiteBriefingStatus = SiteBriefingStatus;
  SiteBriefingStatusPipe = SiteBriefingStatusPipe;
  includeInactiveSiteBriefingForms: boolean = false;
  initialFilters: InitialFilter[] = [{ key: 'includeInactiveSiteBriefingForms', value: false, }];

  constructor(
    private layoutService: LayoutService,
    private siteBriefingService: SiteBriefingService,
    private modalService: ModalService,
    private formService: FormService) { }

  ngOnInit() {
    this.layoutService.header = 'Site Briefing Report';
    this.prepareGridConfig();
  }

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/SiteBriefing/report',
      tableType: Table.SiteBriefingReport,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Site Name, Submitted by and Site Briefing type",
      generalSearchMinimumCharsToSearch: 1,
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/SiteBriefing/report-csv-as-job',
      apiResultCallback: (res) => {
        return {
          data: res?.data?.data,
          totalCount: res?.data?.totalCount,
        }
      }
    });
    this.gridColumns = [
      {
        name: "Site",
        key: "siteTitle",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
      },
      {
        name: "Submitted By",
        key: "submittedByFullName",
        type: FilterType.User,
        propertyNameInQuery: 'submittedBy',
      },
      {
        name: "Mobile",
        key: "submittedByMobile",
        type: FilterType.Mobile,
        propertyNameInQuery: 'submittedByMobiles',
      },
      {
        name: "Site Briefing Type",
        key: "siteBriefingType",
        type: FilterType.SiteBriefingForm,
        propertyNameInQuery: 'siteBriefingTypes',
      },
      {
        name: "Date Submitted",
        key: "startDate",
        type: FilterType.DateTimeRange,
        isArray: false,
        propertyNameInQuery: 'startDate',
      },
      {
        name: "Status",
        key: "status",
        type: FilterType.SiteBriefingStatus,
        propertyNameInQuery: 'statuses',
        gridTemplate: new GridTemplate().ComponentTemplateName('status'),
      },
      {
        name: "Actions",
        key: "",
        gridTemplate: new GridTemplate().ComponentTemplateName('actions'),
        enableFilter: false,
        enableSort: false,
      },
    ];

  }

  openModal(template: any, selectedFormDataId: number) {
    this.selectedFormDataId = selectedFormDataId;
    this.modalService.show(template, 'modal-lg');
  }


  deactiveClicked(formDataId: number) {
    this.modalService
      .confirm('Are you sure you want to deactive selected Site Briefing?', 'Deactivation')
      .subscribe(resp => {
        if (resp === true) {
          this.inProgress = true;
          this.siteBriefingService
            .deactive({ formDataId })
            .subscribe(
              res => {
                if (res.success) {
                  this.grid.loadData(0);
                } else {
                  this.modalService.error(res.message);
                  this.inProgress = false;
                }
              }, err => {
                this.modalService.error(err);
                this.inProgress = false;
              }, () => {
                this.inProgress = false;
              }
            )
        }
      });
  }

  exportPdf(item) {
    this.formService.ExportFormDataAsPdf({ formDataId: item.id, siteId: item.siteId, IncludeAnnouncements: true } as FormDataPdfQueryModel);
  }

  onIncludeInactiveSiteBriefingFormsChanged(value: boolean) {
    this.gridColumns.find(x => x.key == "siteBriefingType").filterSettings = { includeInactiveItems: value };
    this.initialFilters.find(x => x.key == "includeInactiveSiteBriefingForms").value = value;
    this.grid?.loadData();
  }
}
