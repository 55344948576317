import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noop, Observable, Observer, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { FormAskMode } from 'src/app/enums/form-ask-mode.enum';
import { FormScope } from 'src/app/enums/form-scope';
import { FormModel } from 'src/app/models/form/form-model';
import { FormViewModel, FullFormViewModel } from 'src/app/models/form/form-view-model';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';
import { FormType } from "src/app/enums/form-type";
import { FormApplyFor } from "src/app/enums/form-apply-for";
import { CompanySiteScope } from "src/app/enums/company-site-scope";
import { FormApprovalMode } from "src/app/enums/form-approval-mode";
import { ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AssetOperationFormCategoryType } from 'src/app/enums/asset-operation-form-category-type.enum';
import { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PermitClosurePolicy } from 'src/app/enums/permit-closure-policy.enum';
import { CheckListItemViewModel } from 'src/app/custom-controls/check-list-form-control/check-list-item';
import { documentKindValidator } from 'src/app/helpers/mobile-format-validator';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { WorkflowService } from 'src/app/services/workflow.service';
import { WorkflowDefinitionViewModel } from 'src/app/models/workflow/workflow-definition-view-model';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { EnumToArrayPipe } from 'src/app/pipes/enum-to-array.pipe';
import { PermitStatus } from "src/app/enums/permit-status";
import { DocumentKindService } from 'src/app/services/document-kind.service';
import { SupplierDocumentKindViewModel } from 'src/app/models/supplier-document/supplier-document-kind-viewmodel';
import { getSelectedIdList } from 'src/app/helpers/general-functions';

@Component({
  selector: 'obc-upsert-form',
  templateUrl: './upsert-form.component.html',
  styleUrls: ['./upsert-form.component.scss']
})
export class UpsertFormComponent implements OnInit {
  @ViewChild('formCategorySelect') formCategorySelector: NgSelectComponent
  @Output() public hide = new EventEmitter();

  documentKindCheckList: CheckListItemViewModel[] = [];

  formScope = FormScope;
  formType = FormType;
  formApplyFor = FormApplyFor;
  formAskMode = FormAskMode;
  FormApprovalMode = FormApprovalMode;
  inProgress: boolean;

  formGroup: FormGroup;
  formGroups$: Observable<string[]>;
  formGroupLoader: boolean = false;

  permitInUseForm: FormViewModel;
  permitOnholdForm: FormViewModel;
  permitCloseForm: FormViewModel;
  isEnablePermitZone = false;
  isEnablePermitSchedule = false;

  formCategoriesLoading: boolean = false;
  formCategoriesLoadInitiated: boolean = false;
  _formCategories: ToolCategoryViewModel[] = [];
  PermitClosurePolicy = PermitClosurePolicy;
  PermitStatus = PermitStatus;
  OptionalMandatoryState = OptionalMandatoryState;
  optionalMandatoryState: OptionalMandatoryState;
  //workflowList: WorkflowDefinitionViewModel[];
  documentKindList: SupplierDocumentKindViewModel[];


  get formCategories(): ToolCategoryViewModel[] {
    if (this._formCategories.length == 0 && !this.formCategoriesLoadInitiated) {
      this.setFormCategories();
    }
    return this._formCategories
  }
  AssetOperationFormCategoryType = AssetOperationFormCategoryType

  @Input() set form(value: FullFormViewModel) {
    this.resetFormGroup(value);
  }

  formToEdit: FullFormViewModel;

  get isEditMode(): boolean {
    return this.formToEdit?.id > 0
  }

  getFormTypes(): string[] {
    let activeTypes = this.formService.getActiveFormTypes();
    return this.enumToArrayPipe.transform(this.formType)
      .filter(type => activeTypes.some(ac => ac == FormType[type]));
  }

  _permitInUseFormId: number;
  @Input() set permitInUseFormId(value: number) {
    if (value === undefined || value === null)
      return;
    this._permitInUseFormId = value;
  }

  get permitInUseFormId(): number {
    return this._permitInUseFormId;
  }

  _permitOnholdFormId: number;
  @Input() set permitOnholdFormId(value: number) {
    if (value === undefined || value === null)
      return;
    this._permitOnholdFormId = value;
  }

  get permitOnholdFormId(): number {
    return this._permitOnholdFormId;
  }

  _permitCloseFormId: number;
  @Input() set permitCloseFormId(value: number) {
    if (value === undefined || value === null)
      return;
    this._permitCloseFormId = value;
  }

  get permitCloseFormId(): number {
    return this._permitCloseFormId;
  }

  _assetOperatorFormId: number;
  @Input() set assetOperatorFormId(value: number) {
    if (value === undefined || value === null)
      return;
    this._assetOperatorFormId = value;
  }

  get assetOperatorFormId(): number {
    return this._assetOperatorFormId;
  }
  get currentFormType() {
    return this.formGroup.controls['type'].value;
  }

  constructor(private formService: FormService,
    private modalService: ModalService,
    private manageInventoryService: ManageInventoryService,
    private cdr: ChangeDetectorRef,
    private bsModalService: BsModalService,
    private workflowService: WorkflowService,
    private enumToArrayPipe: EnumToArrayPipe,
    private documentKindService: DocumentKindService
  ) {
  }

  ngOnInit() {
    this.getFormGroups();
    this.getDocumentKindList();
  }

  showDocumentKindSection() {
    return this.currentFormType == FormType.SupplierDocumentReview &&
      (this.documentKindList?.length > 1 || (this.documentKindList?.length == 1 && this.formToEdit != null && this.documentKindList[0].id != this.formToEdit?.documentKindIdList[0]));
  }

  onSelectForm($event, formType: string) {
    switch (formType) {
      case 'PermitInUseForm':
        this.formGroup.controls.permitInUseFormId.setValue($event);
        break;
      case 'PermitOnholdForm':
        this.formGroup.controls.permitOnholdFormId.setValue($event);
        break;
      case 'PermitCloseForm':
        this.formGroup.controls.permitCloseFormId.setValue($event);
        break;
      case 'OperatorRegistrationForm':
        this.formGroup.controls.assetOperatorFormId.setValue($event);
        break;
      default:
    }
  }

  resetFormGroup(form: FullFormViewModel = null) {
    this.documentKindService.prepareDocumentKindCheckList(form?.documentKindIdList)
      .subscribe(res => {
        this.documentKindCheckList = res;

        this.formGroup = new FormGroup({
          name: new FormControl(form?.name ?? '', [Validators.required]),
          buttonTitle: new FormControl(form?.buttonTitle ?? '', []),
          scope: new FormControl(form?.scope ?? FormScope.Company, [Validators.required]),
          applyFor: new FormControl(form?.applyFor ?? FormApplyFor.AnyPerson, [Validators.required]),
          type: new FormControl(form?.type ?? FormType.Generic, [Validators.required]),
          askMode: new FormControl(form?.askMode ?? FormAskMode.WhileCheckedIn, [Validators.required]),
          approvalMode: new FormControl(form?.approvalMode ?? FormApprovalMode.AutoApprove, [Validators.required]),
          items: new FormControl(form?.items ?? []),
          visitorTypeIds: new FormControl(form?.visitorTypeIds ?? []),
          groupName: new FormControl(form?.groupName ?? ''),
          isEnable: new FormControl(form?.isEnable ?? true),
          allowUsersToAttachAdditionalDocuments: new FormControl(form?.allowUsersToAttachAdditionalDocuments ?? false),
          isEnableSupplierFormExpiryDate: new FormControl(form?.isEnableSupplierFormExpiryDate ?? false),
          permitInUseFormId: new FormControl(form?.permitInUseFormId ?? []),
          permitOnholdFormId: new FormControl(form?.permitOnholdFormId ?? []),
          permitCloseFormId: new FormControl(form?.permitCloseFormId ?? []),
          permitClosurePolicy: new FormControl(form?.permitClosurePolicy ?? PermitClosurePolicy.OnlyIfOnHold),
          permitStatusAfterApproval: new FormControl(form?.permitStatusAfterApproval ?? PermitStatus.OnHold),
          assetOperatorFormId: new FormControl(form?.assetOperatorFormId ?? []),
          isEnablePermitZone: new FormControl(form?.isEnablePermitZone ?? false),
          isEnablePermitSchedule: new FormControl(form?.isEnablePermitSchedule ?? false),
          assetOperationFormCategoryType: new FormControl(form?.assetOperationFormCategoryType ?? AssetOperationFormCategoryType.All),
          assetOperationFormCategories: new FormControl(form?.assetOperationFormCategories ?? [], form?.assetOperationFormCategoryType == AssetOperationFormCategoryType.Specific ? Validators.required : null),
          documentKinds: new FormControl(this.documentKindCheckList ?? null, form?.type == FormType.SupplierDocumentReview ? documentKindValidator : null),
          optionalMandatoryState: new FormControl(form?.optionalMandatoryState ?? OptionalMandatoryState.Mandatory),
        });

        this._permitInUseFormId = form?.permitInUseFormId;
        this._permitOnholdFormId = form?.permitOnholdFormId;
        this._permitCloseFormId = form?.permitCloseFormId;
        this._assetOperatorFormId = form?.assetOperatorFormId;

        this.formToEdit = form;

        this.formGroup.get('type').valueChanges
          .subscribe((res) => {
            if (res == FormType.SupplierDocumentReview) {
              this.formGroup.get('documentKinds').addValidators(documentKindValidator);
            }
            else {
              this.formGroup.get('documentKinds').clearValidators();
              this.formGroup.get('documentKinds').setValue(null);
            }
            this.formGroup.get('documentKinds').updateValueAndValidity();
          })

        this.formGroup.get('assetOperationFormCategoryType').valueChanges.subscribe(() => {
          this.cdr.detectChanges();
          let assetOperationFormCategoryType = this.formGroup.get('assetOperationFormCategoryType');
          let assetOperationFormCategories = this.formGroup.get('assetOperationFormCategories')
          if (assetOperationFormCategoryType.value == AssetOperationFormCategoryType.Specific) {
            this.formCategorySelector.element.focus();
            assetOperationFormCategories.setValidators(Validators.required);
          }
          else {
            assetOperationFormCategories.clearValidators();
          }
          assetOperationFormCategories.updateValueAndValidity();

        })
      });
  }

  get formCategorySelectCompareFn(): CompareWithFn {
    return (a, b) => a.id == b.id
  }

  get isFormGroupValid(): boolean {
    return this.formGroup.valid;
  }

  createFormModel() {
    let form = {
      name: this.formGroup.controls['name'].value,
      buttonTitle: this.formGroup.controls['buttonTitle'].value,
      scope: (this.currentFormType == this.formType.AssetRegistration || this.currentFormType == this.formType.Generic || this.currentFormType == this.formType.SiteBriefing) ? this.formGroup.controls['scope'].value : CompanySiteScope.Company,
      applyFor: this.formGroup.controls['applyFor'].value,
      type: this.currentFormType,
      askMode: this.formGroup.controls['askMode'].value,
      approvalMode: this.formGroup.controls['approvalMode'].value,
      groupName: this.formGroup.controls['groupName'].value,
      isEnable: this.formGroup.controls['isEnable'].value,
      items: this.formGroup.controls['items'].value,
      visitorTypeIds: this.formGroup.controls['visitorTypeIds'].value,
      isEnableSupplierFormExpiryDate: this.formGroup.controls['isEnableSupplierFormExpiryDate'].value,
      allowUsersToAttachAdditionalDocuments: this.formGroup.controls['allowUsersToAttachAdditionalDocuments'].value,

      permitInUseFormId: (this.currentFormType != this.formType.Permit || this.formGroup.controls['permitInUseFormId'].value == null) ? null : this.formGroup.controls['permitInUseFormId'].value.id,
      permitOnholdFormId: (this.currentFormType != this.formType.Permit || this.formGroup.controls['permitOnholdFormId'].value == null) ? null : this.formGroup.controls['permitOnholdFormId'].value.id,
      permitCloseFormId: (this.currentFormType != this.formType.Permit || this.formGroup.controls['permitCloseFormId'].value == null) ? null : this.formGroup.controls['permitCloseFormId'].value.id,
      permitClosurePolicy: this.currentFormType == this.formType.Permit ? this.formGroup.controls['permitClosurePolicy'].value : null,
      permitStatusAfterApproval: this.currentFormType == this.formType.Permit ? this.formGroup.controls['permitStatusAfterApproval'].value : null,
      assetOperatorFormId: (this.currentFormType != this.formType.AssetRegistration || this.formGroup.controls['assetOperatorFormId'].value == null) ? null : this.formGroup.controls['assetOperatorFormId'].value.id,
      isEnablePermitZone: this.currentFormType != this.formType.Permit ? false : this.formGroup.controls['isEnablePermitZone'].value,
      isEnablePermitSchedule: this.currentFormType != this.formType.Permit ? false : this.formGroup.controls['isEnablePermitSchedule'].value,
      assetOperationFormCategories: this.formGroup.controls['assetOperationFormCategories'].value?.map((item => item.id)),
      assetOperationFormCategoryType: this.formGroup.controls['assetOperationFormCategoryType'].value,
      optionalMandatoryState: this.formGroup.controls['optionalMandatoryState'].value,
    } as FormModel;

    switch (form.type) {
      case FormType.Generic:
        form.applyFor = FormApplyFor.Individual;
        break;
      case FormType.SupplierDocumentReview:
        form.askMode = FormAskMode.AnyTime;
        form.scope = CompanySiteScope.Company;
        form.applyFor = FormApplyFor.Supplier;
        form.documentKindIdList = getSelectedIdList(this.documentKindCheckList);
        break;
      case FormType.Permit:
        form.askMode = FormAskMode.AnyTime;
        form.scope = CompanySiteScope.Site;
        break;
    }

    return form;
  }

  formModel: FormModel;
  bsModalRef: BsModalRef;

  onModalPreview(template: any) {
    this.formModel = this.createFormModel();

    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-xl",
      backdrop: true,
      ignoreBackdropClick: false,
    })
  }

  addForm() {

    let form = this.createFormModel();

    // if (!form.items?.length) {
    //   this.modalService.error('Form must have at least one question or document or announcement!');
    //   return;
    // }

    this.inProgress = true;
    let subscription;
    if (this.formToEdit) {
      subscription = this.formService.editForm(this.formToEdit.id, form)
    }
    else
      subscription = this.formService.addForm(form);

    subscription.subscribe(
      res => {
        if (res.success) {
          this.hide.emit();
        } else {
          this.modalService.error(res.message);
          this.inProgress = false;
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }

  onSelectedVisitorTypeChange($event) {
    this.formGroup.controls.visitorTypeIds.setValue($event);
  }

  getDocumentKindList() {
    this.documentKindService.list()
      .subscribe(res => {
        this.documentKindList = res;
        this.documentKindService.prepareDocumentKindCheckList(this.formToEdit?.documentKindIdList)
          .subscribe(res => {
            this.documentKindCheckList = res;
            this.formGroup?.controls.documentKinds.setValue(this.documentKindCheckList);
          });
      });
  }


  getFormGroups() {
    this.formGroups$ = new Observable((observer: Observer<string>) => {
      observer.next(this.formGroup.get('groupName').value);
    }).pipe(
      switchMap((groupName: string) => {
        if (groupName) {
          this.formGroupLoader = true;
          return this.formService.getFormGroups(groupName).pipe(
            map((data: string[]) => (data && data) || []),
            tap(
              () => noop,
              (err) => {
                let rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.formGroupLoader = false;
            })
          );
        }

        return of([]);
      })
    );
  }

  onSelectFormGroup(event) {
    this.formGroup.get('groupName').setValue(event.value);
  }

  orderedItems(items) {
    this.formGroup.controls['items'].setValue(items);
  }

  get isVisitorTypeFieldVisible(): boolean {
    let formType = this.formGroup.controls.type.value;
    return [
      FormType.SupplierDocumentReview,
      FormType.SupplierForm,
      FormType.AssetRegistration,
      FormType.AssetOperator,
      FormType.AssetOperation,
    ].includes(formType) == false;
  }

  setFormCategories() {
    this.formCategoriesLoadInitiated = true;
    this.formCategoriesLoading = true;
    this.manageInventoryService.getToolCategories().pipe(finalize(() => this.formCategoriesLoading = false))
      .subscribe(res => {
        this._formCategories = res;
      }, err => {
        this.modalService.error('Getting form categories failed!')
      }, () => {
        this.formCategoriesLoading = false;
      })
  }
}
