import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, EmailValidator, RequiredValidator } from '@angular/forms';
import { CompanyViewModel, BaseCompanyViewModel } from 'src/app/models/company-viewmodel';
import { CompanyService } from 'src/app/services/company.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyUserViewModel } from 'src/app/models/company-user-view-model';
import { e168Validator, mobileIsValid } from 'src/app/helpers/mobile-format-validator';
import { LayoutService } from 'src/app/services/layout.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { CountryService } from 'src/app/services/country.service';
import { Timezone } from 'src/app/models/time-zone';
import { ProductPlanService } from 'src/app/services/product-plan.service';
import { requestProductPlanModel } from 'src/app/models/product-plan-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { CompanyCategory } from 'src/app/enums/company-category';
import { emailListValidator } from 'src/app/helpers/email-list-validator';
import { SendWelcomeEmailUserProfile } from 'src/app/models/user-profile-viewmodel';
import { CompanyTypeViewModel } from '../../../models/company-type-viewmodel';
import { pagingSortingModel } from 'src/app/models/paging_model';
import { SortOrder } from 'src/app/enums/sort-order-enum';
import { CompaniesQueryModel } from 'src/app/models/get-companies-query-model';
import { PaginationInfo } from 'src/app/models/pagination-info';
import { CompanyStats } from 'src/app/models/company-stats';
import { getPageSizeByUrl } from "../../../helpers/general-functions";
import { UserService } from 'src/app/services/user.service';
import { GodUserPermission } from 'src/app/enums/god-user-permission.enum';

@Component({
  selector: 'obc-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.css']
})
export class ManageCompaniesComponent implements OnInit {
  //Paging
  pagingInfo: pagingSortingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 30,
    sortBy: null,
    sortOrder: null,
    totalCount: 0,
  }
  enableWorkflowManagement: boolean = false;
  sortBy(column: string) {
    if (column == this.pagingInfo.sortBy) {
      this.pagingInfo.sortOrder = this.pagingInfo.sortOrder == SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    } else {
      this.pagingInfo.sortBy = column;
      this.pagingInfo.sortOrder = SortOrder.ASC;
    }
    this.getCompanies()
  }
  filterListTerm: string;
  filterFormcontrol = new FormControl();
  newCompanyFormGroup: FormGroup;
  editCompanyFormGroup: FormGroup;
  newUserFormGroup: FormGroup;
  inProgress: boolean = false;
  inAddEditProgress: boolean = false;
  companies: CompanyViewModel[] = null;
  templateCompanies: CompanyViewModel[] = null;
  users: CompanyUserViewModel[] = null;
  stats: CompanyStats[] = [];
  selectedCompany: CompanyViewModel = null;
  bsModalRef: BsModalRef;
  cloneACompanyTemplate: boolean = false;
  activePlanViewMode: ('view' | 'edit') = 'view';

  templateCompanyId: number = null;
  message: string;
  errorMessage: string;
  errorEditModalMessage: string;
  timezones: Timezone[];
  plans: requestProductPlanModel[];
  companyCategory = CompanyCategory;
  companyTypes: CompanyTypeViewModel[];

  constructor(private layoutService: LayoutService,
    private companyService: CompanyService,
    private countrySerivce: CountryService,
    private productPlanService: ProductPlanService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private bsModalService: BsModalService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.layoutService.header = "Manage Companies";
    this.newCompanyFormGroup = this.fb.group({
      title: new FormControl('', [Validators.required]),
      companyTypeId: new FormControl(this.userService.defaultCompanyType.value),
      timezone: new FormControl(this.userService.defaultServerTimeZone.value, [Validators.required]),
      maxSites: new FormControl(null, [Validators.min(0)]),
      isTemplate: new FormControl(false, [Validators.required]),
      description: new FormControl('')
    });

    this.getCompanyTypes();
    this.countrySerivce.getTimeZones().subscribe(res => this.timezones = res);
    this.getProductPlans();
    this.getCompanies();
    this.getTemplateCompanies();
    this.CheckWorkflowManagementPermission();

    this.filterFormcontrol.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((searchTerm) => {
      this.filterListTerm = searchTerm;
      if (searchTerm == '' || (searchTerm != null && searchTerm.length >= 2)) {
        this.getCompanies();
      }
    });
  }

  getFormEntity(): CompaniesQueryModel {
    var query = {
      pagingInfo: {
        pageNumber: this.pagingInfo.pageNumber,
        pageSize: this.pagingInfo.pageSize,
        sortBy: this.pagingInfo.sortBy,
        sortOrder: this.pagingInfo.sortOrder,
      },
      searchTerm: this.filterListTerm,
    } as CompaniesQueryModel;
    return query;
  }

  private getCompanies(pageNumber?: number) {
    if (pageNumber != null && pageNumber != undefined)
      this.pagingInfo.pageNumber = pageNumber;
    var query = this.getFormEntity();
    this.inProgress = true;

    this.companyService.getCompanies(query).subscribe(res => {
      this.companies = res.data;
      this.pagingInfo.totalCount = res.totalCount;

      this.companies.forEach((c) => {
        c.tempdata = [];
        c.tempdata.push(new FormControl(c.activePlanId));
        c.tempdata.push('view');
      });
      this.inProgress = false;
    });
  }

  getCompanyTypes() {
    this.companyService.getCompanyTypes()
      .subscribe(res => this.companyTypes = res);
  }

  getTemplateCompanies() {
    this.companyService.getTemplateCompanies()
      .subscribe(res => this.templateCompanies = res);
  }

  getCompanyTypeTitle(companyTypeId: number) {
    return this.companyTypes?.find(type => type.id == companyTypeId)?.title ?? 'General';
  }

  switchViewMode(mode, company: CompanyViewModel) {
    company.tempdata[1] = mode;
    if (mode == 'edit')
      company.tempdata[0].setValue(company.activePlanId);
  }

  private getProductPlans() {
    this.inProgress = true;
    this.productPlanService.getAllPlans().subscribe(res => {
      this.plans = res;
      this.inProgress = false;
    });
  }

  getPlanTitle(plan: requestProductPlanModel): string {
    if (plan == null) return 'No Plan';

    var period = '';
    switch (plan.billingPeriod) {
      case 0: period = 'Yearly'; break;
      case 1: period = 'Monthly'; break;
      case 2: period = 'Semiannually'; break;
    }

    return `${plan.title} ${period}`;
  }

  setActivePlan(company: CompanyViewModel) {
    if (company.activePlanId === company.tempdata[0].value) {
      company.tempdata[1] = 'view';
      return;
    }
    var plan = company.tempdata[0].value != null ? this.plans.filter(p => p.id == company.tempdata[0].value)[0] : null;
    var newPlanTitle = this.getPlanTitle(plan);
    var currentPlanTitle = company.activePlan ? `from ${company.activePlan}` : '';
    this.modalService.confirm(`Are you sure you want change plan ${currentPlanTitle} to ${newPlanTitle}?`).subscribe(confirmed => {
      if (confirmed) {
        this.inProgress = true;
        this.companyService.setActivePlan(company.id, company.tempdata[0].value).subscribe(res => {
          this.inProgress = false;
          if (res) {
            this.getCompanies();
          }
        }), err => {
          this.switchViewMode('view', company);
          return;
        }, () => {
          this.switchViewMode('view', company);
          return;
        }
      } else {
        company.tempdata[1] = 'view';
        return;
      }
    })
  }

  addCompany() {
    this.errorMessage = null;
    var company = new CompanyViewModel();
    company.title = this.newCompanyFormGroup.get('title').value;
    company.companyTypeId = this.newCompanyFormGroup.get('companyTypeId').value;
    company.description = this.newCompanyFormGroup.get('description').value;
    company.isActive = true;
    company.maxSites = this.newCompanyFormGroup.get('maxSites').value;
    company.timezoneId = parseInt(this.newCompanyFormGroup.get('timezone').value.toString());
    company.isTemplate = this.newCompanyFormGroup.get('isTemplate').value;

    this.inProgress = true;
    this.companyService.addCompany(company, this.templateCompanyId).subscribe(res => {
      company.id = res;
      //this.companies.push(company);
      this.inProgress = false;
      this.newCompanyFormGroup.reset();
      this.newCompanyFormGroup.controls.isTemplate.setValue(false);
      this.templateCompanyId = null;
      this.cloneACompanyTemplate = false;
      this.getCompanies();
    }, err => {
      this.errorMessage = err;
      this.inProgress = false;
      setTimeout(() => {
        this.errorMessage = null;
      }, 10000)

    });
  }

  onShowEditModal(template, company: CompanyViewModel) {
    this.errorEditModalMessage = null;
    this.selectedCompany = company;
    this.editCompanyFormGroup = this.fb.group({
      id: new FormControl(company.id),
      title: new FormControl(company.title, [Validators.required, Validators.maxLength(500)]),
      companyTypeId: new FormControl(company.companyTypeId),
      description: new FormControl(company.description),
      originalMaxSites: new FormControl(company.originalMaxSites, [Validators.min(0)]),
      smsSoftLimit: new FormControl(company.smsSoftLimit, [Validators.min(0)]),
      smsHardLimit: new FormControl(company.smsHardLimit, [Validators.min(0)]),
      companySupervisorEmailAddress: new FormControl(company.companySupervisorEmailAddress, [emailListValidator]),
      isActive: new FormControl(company.isActive),
      isTemplate: new FormControl(company.isTemplate, [Validators.required]),
    });
    this.bsModalRef = this.bsModalService.show(template);
  }

  onSubmitEditForm() {
    if (this.editCompanyFormGroup.valid) {
      var model = {
        id: this.selectedCompany.id,
        title: this.editCompanyFormGroup.get("title").value,
        companyTypeId: this.editCompanyFormGroup.get("companyTypeId").value,
        description: this.editCompanyFormGroup.get("description").value,
        maxSites: this.editCompanyFormGroup.get("originalMaxSites").value,
        smsSoftLimit: this.editCompanyFormGroup.get("smsSoftLimit").value,
        smsHardLimit: this.editCompanyFormGroup.get("smsHardLimit").value,
        isActive: this.editCompanyFormGroup.get("isActive").value,
        companySupervisorEmailAddress: this.editCompanyFormGroup.get("companySupervisorEmailAddress").value,
        isTemplate: this.editCompanyFormGroup.get("isTemplate").value
      } as BaseCompanyViewModel;
      this.inAddEditProgress = true;
      this.companyService.editCompany(model).subscribe(res => {
        this.errorEditModalMessage = null;

        this.getCompanies();
        this.bsModalRef.hide();
        this.inAddEditProgress = false;
      }, err => {
        this.errorEditModalMessage = err;
        this.inAddEditProgress = false;
        setTimeout(() => {
          this.errorEditModalMessage = null;
        }, 10000)

      });
    }
  }

  onShowUsersModal(template, company: CompanyViewModel) {
    this.selectedCompany = company;
    this.users = [];
    this.message = '';
    this.newUserFormGroup = this.fb.group({
      mobile: new FormControl('', [Validators.required, e168Validator]),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      isAdmin: new FormControl(false),
    });
    this.getCompanyUserList();
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  getCompanyUserList() {
    this.inAddEditProgress = true;
    this.companyService.getCompanyUsers(this.selectedCompany.id)
      .pipe(finalize(() => { this.inAddEditProgress = false; }))
      .subscribe(res => {
        this.users = res;
      });
  }

  addNewUser() {
    if (!this.newUserFormGroup.valid) return;
    this.message = '';
    var newUser = {
      id: 0,
      companyId: this.selectedCompany.id,
      mobile: this.newUserFormGroup.get('mobile').value,
      firstName: this.newUserFormGroup.get('firstname').value,
      lastName: this.newUserFormGroup.get('lastname').value,
      isAdmin: this.newUserFormGroup.get('isAdmin').value ?? false,
    } as CompanyUserViewModel;
    if (!this.isValid(newUser)) return;
    this.inAddEditProgress = true;
    this.companyService.addUser(newUser).subscribe(res => {
      this.users.push(res);
      this.inAddEditProgress = false;
      this.newUserFormGroup.reset();
    }, (error) => {
      this.inAddEditProgress = false;
      this.message = error;
    });
  }

  isValid(user: CompanyUserViewModel): boolean {
    if (!user.mobile || user.mobile === '' || !mobileIsValid(user.mobile)) {
      this.message = 'the mobile number is not valid';
      return false;
    }

    let duplicatd = this.users.find(c => c.mobile.trim() === user.mobile.trim());
    if (duplicatd) {
      this.message = 'the mobile number is duplicated';
      return false;
    }

    return true;
  }

  onRemoveUser(user: CompanyUserViewModel) {
    this.inAddEditProgress = true;
    this.companyService.removeUser(user.id).subscribe(res => {
      if (res) {
        var index = this.users.indexOf(user);
        this.users.splice(index, 1);
      }
      this.inAddEditProgress = false;
    });
  }

  selectedUserIsAdmin: boolean;
  initEdit(user: CompanyUserViewModel) {
    this.selectedUserIsAdmin = user.isAdmin;
    user.inEdit = true;
  }

  updateUserAdminState(user: CompanyUserViewModel) {
    if (this.selectedUserIsAdmin === user.isAdmin) return;
    this.inAddEditProgress = true;
    this.companyService.updateUserAdminState(user.id, this.selectedUserIsAdmin)
      .pipe(finalize(() => { this.inAddEditProgress = false; }))
      .subscribe(res => {
        this.getCompanyUserList();
      }, error => {
        console.log(error);
      });
  }

  sendingWelcomeEmail() {
    this.inProgress = true;
    this.companyService.sendWelcomeEmail(this.sendWelcomeFormGroup.value as SendWelcomeEmailUserProfile)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.bsModalRef.hide();
        this.modalService.info("Welcome Email Sent Successfully");
      },
        (e) => this.modalService.error("Send Welcome Email Failed!"));
  }

  sendWelcomeFormGroup: FormGroup;
  onSendWelcomeEmail(template, companyId: number) {
    this.sendWelcomeFormGroup = new FormGroup({
      companyId: new FormControl(companyId),
      email: new FormControl('', [Validators.email, Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required])
    });
    this.bsModalRef = this.bsModalService.show(template);
  }

  onPageChange(paginationInfo: PaginationInfo) {
    this.pagingInfo.pageSize = paginationInfo.pageSize;
    this.getCompanies(paginationInfo.pageNumber);
  }

  inGettingStatsProgress: boolean = false;
  bsCompanyStatsModalRef: any = null;
  onGetCompanyStats(template, companyId: number) {
    this.stats = [];
    this.inGettingStatsProgress = true;
    this.bsCompanyStatsModalRef = this.bsModalService.show(template);
    this.companyService.getCompanyStats(companyId)
      .pipe((finalize(() => this.inGettingStatsProgress = false)))
      .subscribe(res => {
        this.stats = res;
      }, err => {
        this.modalService.error("unable to get company statistics")
      })
  }
  GetYearMonth(stat: CompanyStats) {
    return `${this.MonthNames.en.month_names[stat.month - 1]} ${stat.year}`;
  }
  get MonthNames() {
    return {
      en: {
        month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }
    };
  };

  CheckWorkflowManagementPermission() {
    this.enableWorkflowManagement = this.userService.hasGodUserPermissionForCompany(GodUserPermission.WorkflowManagement);
  }

  getWorkflowManagementRoute(company: CompanyViewModel) {
    return `/company-workflow-template/${company.id}/${encodeURIComponent(company.title)}`;
  }
}
